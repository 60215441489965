import Head from 'next/head';
import { wrapper } from '../redux/store';
import Layout from '../components/Layout';
import '../../assets/css/bootstrap.min.css';
import '../../assets/css/owl.carousel.css';
import '../../assets/css/style.css';
import '../../assets/css/slick.css';
import NextNProgress from 'nextjs-progressbar';
import { useRouter } from 'next/router';
import { useEffect } from 'react';
import Router from "next/router";

/**
 * React hook that forces a scroll reset to a particular set of coordinates in the document
 * after `next/router` finishes transitioning to a new page client side. It smoothly scrolls back to
 * the top by default.
 *
 * @see https://github.com/vercel/next.js/issues/3249
 * @see https://github.com/vercel/next.js/issues/15206
 * @see https://developer.mozilla.org/en-US/docs/Web/API/ScrollToOptions
 * @param {ScrollOptions} [options={}] Hook options.
 * @param {ScrollBehavior} [options.behavior='smooth'] Specifies whether the scrolling should animate smoothly,
 *  or happen instantly in a single jump.
 * @param {number} [options.left=0] Specifies the number of pixels along the X axis to scroll the window.
 * @param {number} [options.top=0] Specifies the number of pixels along the Y axis to scroll the window.
 */
function useRouterScroll({ behavior = 'smooth', left = 0, top = 0 } = {}) {
  const router = useRouter();
  useEffect(() => {
    // Scroll to given coordinates when router finishes navigating
    // This fixes an inconsistent behaviour between `<Link/>` and `next/router`
    // See https://github.com/vercel/next.js/issues/3249
    const handleRouteChangeComplete = () => {
      window.scrollTo({ top, left, behavior });
      document.body.scrollTop = top;
    };

    router.events.on('routeChangeComplete', handleRouteChangeComplete);

    // If the component is unmounted, unsubscribe from the event
    return () => {
      router.events.off('routeChangeComplete', handleRouteChangeComplete);
    };

  }, [behavior, left, top]);
}


function App({ Component, pageProps }) {
  const getLayout = Component.getLayout || ((page) => page);
  useRouterScroll();
  if (Component.getLayout) {
    return getLayout(<><NextNProgress color='#d0ab6d' />
      <Component {...pageProps} />
    </>);

  } return (
    <Layout>
      <NextNProgress color='#d0ab6d' />
      <Component {...pageProps} />
    </Layout>
  );
}

export default wrapper.withRedux(App);