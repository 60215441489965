import PropTypes from 'prop-types';
import Head from 'next/head';
import Link from 'next/link';
import { Form, message, Select } from 'antd';
import React, { useEffect, useState } from 'react';
import { shallowEqual, useSelector, useDispatch } from 'react-redux';
import { useRouter } from 'next/router';
import MetaSeo from './MetaSeo';
import { getAllServices } from '../redux/actions/services';
import { getDisplayName } from 'next/dist/shared/lib/utils';
import useTrans from '../hooks/useTrans';
function Layout({ children, webViewMobile, web }) {
  const dispatch = useDispatch();
  const [service, setService] = useState([]);
  const [show, setShow] = useState();
  const [marginTop, setMarginTop] = useState(0);
  const [show_menu_mobile, setShowMenuMobile] = useState();
  const [value_select, setValueSelect] = useState('vi');
  const [show_header, setShowHeader] = useState('ndbasdbadbqbabas');
  const [padding_top, setPaddingTop] = useState('');
  const trans = useTrans();
  const router = useRouter();
  const [y, setY] = useState(0);
  useEffect(() => {
    localStorage.setItem('lg', router.locale);
    getListServiceAll();
    setValueSelect(router.locale);
    window.addEventListener('scroll', onScroll);
  }, router.locale);

  const getListServiceAll = async () => {
    const data = await getAllServices({}, dispatch);
    if (data) {
      setService(data?.rows);
    }
  };
  const displayDrop = async () => {
    if (show === 'show') {
      setShow('');
    } else {
      setShow('show');
    }
  };
  const showMenuApp = () => {
    if (show_menu_mobile === 'open') {
      setShowMenuMobile('');
    } else {
      setShowMenuMobile('open');
    }
  };
  const setLanguageSelect = key => {
    setValueSelect(key);
    setShow('');
    const { pathname, asPath, query } = router;
    router.push({ pathname, query }, asPath, { locale: key });
  };
  const onScroll = e => {
    // let window = e.currentTarget;
    if (!window.vinhY) {
      window.vinhY = 0;
    }
    if (window.pageYOffset < 175) {
      setShowHeader('nandnadabdb');
    } else if (window.vinhY > window.pageYOffset) {
      setShowHeader('sticky');
    } else if (window.vinhY < window.pageYOffset) {
      setShowHeader('qeadsad');
    }

    window.vinhY = window.pageYOffset;
  };
  let positionY = React.useRef(null);
  useEffect(() => {
    if (show_menu_mobile) {
      lockScroll();
    } else {
      unlockScroll();
    }
  }, [show_menu_mobile]);
  const lockScroll = React.useCallback(() => {
    positionY.current = window.scrollY;
    document.body.style.overflowY = 'hidden';

    document.body.style.position = 'fixed';
    document.body.style.top = `${positionY.current}px`;
  }, []);

  const unlockScroll = React.useCallback(() => {
    document.body.style.overflowY = '';

    document.body.style.position = '';
    document.body.style.top = '';
    scrollTo(0, positionY.current);
  }, []);
  // const dataSeo = {
  //     url: `https://staging.tcom-japan.com`,
  //     type: 'website',
  //     title: "TCOM - Công ty công nghệ đa lĩnh vực hàng đầu Việt Nam",
  //     description: 'Cung cấp dịch vụ offshore cho thị trường Nhật Bản. Tư vấn và phát triển các sản phẩm dựa trên công nghệ Trí tuệ nhân tạo A.I, Blockchain, AR/VR, Livestreaming cho các tổ chức, doanh nghiệp trong nước và quốc tế.',
  //     domain: 'staging.tcom-japan.com',
  //     // image: 'https://staging.tcom-japan.com/assets/images/about/gallery-2.png',
  //     card: 'summary_large_image',
  // };
  return (
    <>
      {/* <MetaSeo /> */}
      <Head>
        <meta charSet='UTF-8' />
        <meta httpEquiv='X-UA-Compatible' content='IE=edge' />
        <meta
          name='viewport'
          content='width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no'
        />
        <title>TCOM</title>
      </Head>
      <div id={web}>
        <div id='header' className={`${show_header}`}>
          <div className='header-desktop'>
            <div className='container-fluid'>
              <div className='row'>
                <div className='col-auto col-left'>
                  <Link href='/'>
                    <a className='logo'>
                      <img
                        className='width-respon'
                        src='/assets/images/logo-tcom-japan.svg'
                        alt='TCOM JAPAN'
                      />
                    </a>
                  </Link>
                </div>
                <div className='col col-right'>
                  <div className='megamenu'>
                    <ul>
                      <li className={web === 'about' ? 'active' : ''}>
                        <Link href='/about'>
                          <a>{trans.layout.about}</a>
                        </Link>
                      </li>
                      <li className={web === 'service' ? 'active' : ''}>
                        <Link href='/service'>
                          <a>{trans.layout.service}</a>
                        </Link>
                      </li>
                      <li className={web === 'offshore' ? 'active' : ''}>
                        <Link href='/offshore'>
                          <a>{trans.layout.offshore}</a>
                        </Link>
                      </li>
                      <li className={web === 'solution' ? 'active' : ''}>
                        <Link href='/solution'>
                          <a>{trans.layout.solution}</a>
                        </Link>
                      </li>
                      <li className={web === 'project' ? 'active' : ''}>
                        <Link href='/project'>
                          <a>{trans.layout.project}</a>
                        </Link>
                      </li>
                      {/* <li className={web === 'recruitment' ? 'active' : ''}><Link href='/recruitment'><a>{trans.layout.job}</a></Link></li> */}
                      <li className={web === 'news' ? 'active' : ''}>
                        <Link href='/news'>
                          <a>{trans.layout.news}</a>
                        </Link>
                      </li>
                      <li className={web === 'contact' ? 'active' : ''}>
                        <Link href='/contact'>
                          <a>{trans.layout.contact}</a>
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='header-mobile'>
            <div className='container'>
              <div className='row'>
                <div className='col'>
                  <Link href='/'>
                    <a>
                      <img
                        width={119}
                        src='/assets/images/logo-tcom-japan.svg'
                        alt='TCOM'
                      />
                    </a>
                  </Link>
                </div>
                <div className='col-auto'>
                  <a onClick={() => showMenuApp()} className='js-open-canvas'>
                    <svg
                      width={35}
                      height={24}
                      viewBox='0 0 35 24'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <path
                        d='M0.947754 2.00004C0.947754 1.55801 1.12335 1.13409 1.43591 0.82153C1.74847 0.508969 2.17239 0.333374 2.61442 0.333374H32.6144C33.0564 0.333374 33.4804 0.508969 33.7929 0.82153C34.1055 1.13409 34.2811 1.55801 34.2811 2.00004C34.2811 2.44207 34.1055 2.86599 33.7929 3.17855C33.4804 3.49111 33.0564 3.66671 32.6144 3.66671H2.61442C2.17239 3.66671 1.74847 3.49111 1.43591 3.17855C1.12335 2.86599 0.947754 2.44207 0.947754 2.00004Z'
                        fill='white'
                      />
                      <path
                        d='M0.947754 12.0533C0.947754 11.6112 1.12335 11.1873 1.43591 10.8748C1.74847 10.5622 2.17239 10.3866 2.61442 10.3866H32.6144C33.0564 10.3866 33.4804 10.5622 33.7929 10.8748C34.1055 11.1873 34.2811 11.6112 34.2811 12.0533C34.2811 12.4953 34.1055 12.9192 33.7929 13.2318C33.4804 13.5443 33.0564 13.7199 32.6144 13.7199H2.61442C2.17239 13.7199 1.74847 13.5443 1.43591 13.2318C1.12335 12.9192 0.947754 12.4953 0.947754 12.0533Z'
                        fill='white'
                      />
                      <path
                        d='M2.61442 20.4399C2.17239 20.4399 1.74847 20.6155 1.43591 20.9281C1.12335 21.2407 0.947754 21.6646 0.947754 22.1066C0.947754 22.5486 1.12335 22.9726 1.43591 23.2851C1.74847 23.5977 2.17239 23.7733 2.61442 23.7733H32.6144C33.0564 23.7733 33.4804 23.5977 33.7929 23.2851C34.1055 22.9726 34.2811 22.5486 34.2811 22.1066C34.2811 21.6646 34.1055 21.2407 33.7929 20.9281C33.4804 20.6155 33.0564 20.4399 32.6144 20.4399H2.61442Z'
                        fill='white'
                      />
                    </svg>
                  </a>
                  <div className={`canvas-menu ${show_menu_mobile}`}>
                    <div className='top-menu'>
                      <Link href='/'>
                        <a>
                          <img
                            width={90}
                            src='/assets/images/logo-tcom-japan.svg'
                            alt='TCOM'
                          />
                        </a>
                      </Link>
                      <a
                        onClick={() => showMenuApp()}
                        className='js-close-canvas'
                      >
                        <svg
                          width={12}
                          height={12}
                          viewBox='0 0 12 12'
                          fill='none'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <path
                            d='M0.968875 0.861454C1.03854 0.791609 1.12131 0.736194 1.21242 0.698385C1.30354 0.660575 1.40122 0.641113 1.49988 0.641113C1.59853 0.641113 1.69621 0.660575 1.78733 0.698385C1.87844 0.736194 1.96121 0.791609 2.03088 0.861454L5.99987 4.83195L9.96887 0.861454C10.0386 0.791722 10.1214 0.736407 10.2125 0.698668C10.3036 0.66093 10.4013 0.641506 10.4999 0.641506C10.5985 0.641506 10.6961 0.66093 10.7872 0.698668C10.8784 0.736407 10.9611 0.791722 11.0309 0.861454C11.1006 0.931185 11.1559 1.01397 11.1937 1.10508C11.2314 1.19619 11.2508 1.29384 11.2508 1.39245C11.2508 1.49107 11.2314 1.58872 11.1937 1.67983C11.1559 1.77094 11.1006 1.85372 11.0309 1.92345L7.06037 5.89245L11.0309 9.86145C11.1006 9.93118 11.1559 10.014 11.1937 10.1051C11.2314 10.1962 11.2508 10.2938 11.2508 10.3925C11.2508 10.4911 11.2314 10.5887 11.1937 10.6798C11.1559 10.7709 11.1006 10.8537 11.0309 10.9235C10.9611 10.9932 10.8784 11.0485 10.7872 11.0862C10.6961 11.124 10.5985 11.1434 10.4999 11.1434C10.4013 11.1434 10.3036 11.124 10.2125 11.0862C10.1214 11.0485 10.0386 10.9932 9.96887 10.9235L5.99987 6.95295L2.03088 10.9235C1.96114 10.9932 1.87836 11.0485 1.78725 11.0862C1.69614 11.124 1.59849 11.1434 1.49988 11.1434C1.40126 11.1434 1.30361 11.124 1.2125 11.0862C1.12139 11.0485 1.03861 10.9932 0.968875 10.9235C0.899143 10.8537 0.843829 10.7709 0.80609 10.6798C0.768352 10.5887 0.748928 10.4911 0.748928 10.3925C0.748928 10.2938 0.768352 10.1962 0.80609 10.1051C0.843829 10.014 0.899143 9.93118 0.968875 9.86145L4.93938 5.89245L0.968875 1.92345C0.899031 1.85379 0.843616 1.77102 0.805806 1.6799C0.767997 1.58879 0.748535 1.4911 0.748535 1.39245C0.748535 1.2938 0.767997 1.19612 0.805806 1.105C0.843616 1.01389 0.899031 0.931122 0.968875 0.861454Z'
                            fill='#525252'
                          />
                        </svg>
                      </a>
                    </div>
                    <div className='megamenu'>
                      <ul>
                        <li
                          onClick={() => showMenuApp()}
                          className={web === 'home' ? 'active' : ''}
                        >
                          <Link href='/'>
                            <a>{trans.layout.home}</a>
                          </Link>
                        </li>
                        <li
                          onClick={() => showMenuApp()}
                          className={web === 'about' ? 'active' : ''}
                        >
                          <Link href='/about'>
                            <a>{trans.layout.about}</a>
                          </Link>
                        </li>
                        <li
                          onClick={() => showMenuApp()}
                          className={web === 'service' ? 'active' : ''}
                        >
                          <Link href='/service'>
                            <a>{trans.layout.service}</a>
                          </Link>
                        </li>
                        <li
                          onClick={() => showMenuApp()}
                          className={web === 'offshore' ? 'active' : ''}
                        >
                          <Link href='/offshore'>
                            <a>{trans.layout.offshore}</a>
                          </Link>
                        </li>
                        <li
                          onClick={() => showMenuApp()}
                          className={web === 'solution' ? 'active' : ''}
                        >
                          <Link href='/solution'>
                            <a>{trans.layout.solution}</a>
                          </Link>
                        </li>
                        <li
                          onClick={() => showMenuApp()}
                          className={web === 'project' ? 'active' : ''}
                        >
                          <Link href='/project'>
                            <a>{trans.layout.project}</a>
                          </Link>
                        </li>
                        {/* <li onClick={() => showMenuApp()} className={web === 'recruitment' ? 'active' : ''}><a href='/recruitment'>{trans.layout.job}</a></li> */}
                        <li
                          onClick={() => showMenuApp()}
                          className={web === 'news' ? 'active' : ''}
                        >
                          <Link href='/news'>
                            <a>{trans.layout.news}</a>
                          </Link>
                        </li>
                        <li
                          onClick={() => showMenuApp()}
                          className={web === 'contact' ? 'active' : ''}
                        >
                          <Link href='/contact'>
                            <a>{trans.layout.contact}</a>
                          </Link>
                        </li>
                      </ul>
                    </div>
                    <div className='language'>
                      {/* <div className='dropdown'>
                                                <button data-toggle='dropdown' data-display='static'>
                                                    <svg width={16} height={12} viewBox='0 0 16 12' fill='none' xmlns='http://www.w3.org/2000/svg'>
                                                        <path d='M0.025 11.5998V0.449805H15.975V11.5998H0.025Z' fill='white' stroke='black' strokeWidth='0.05' />
                                                        <path d='M7.99621 2.72949C9.80984 2.72949 11.2776 4.2029 11.2776 6.02353C11.2776 7.84417 9.80984 9.31756 7.99621 9.31756C6.18258 9.31756 4.71484 7.84417 4.71484 6.02353C4.71484 4.2029 6.18258 2.72949 7.99621 2.72949Z' fill='#D61F30' />
                                                    </svg>
                                                    <span>Japan</span>
                                                    <svg width={8} height={5} viewBox='0 0 8 5' fill='none' xmlns='http://www.w3.org/2000/svg'>
                                                        <g clipPath='url(#clip0_401_9445)'>
                                                            <path d='M7.94398 0.731689L4.12685 4.55302C4.05539 4.62449 3.94188 4.62449 3.87042 4.55302L0.053287 0.731689C-0.0602179 0.618184 0.0196559 0.424805 0.179404 0.424805H7.81786C7.97761 0.424805 8.05749 0.618184 7.94398 0.731689Z' fill='#121212' />
                                                        </g>
                                                        <defs>
                                                            <clipPath id='clip0_401_9445'>
                                                                <rect width={8} height='4.17867' fill='white' transform='translate(0 0.424805)' />
                                                            </clipPath>
                                                        </defs>
                                                    </svg>
                                                </button>
                                                <div className='dropdown-menu'>
                                                    <a className='dropdown-item' href='#'>
                                                        <img src='/assets/images/flags/vietnam.png' alt='Viet Nam' />
                                                        Viet Nam
                                                    </a>
                                                    <a className='dropdown-item' href='#'>
                                                        <img src='/assets/images/flags/english.png' alt='English' />
                                                        English
                                                    </a>
                                                    <a className='dropdown-item' href='#'>
                                                        <img src='/assets/images/flags/japan.png' alt='Japan' />
                                                        Japan
                                                    </a>
                                                </div>
                                            </div> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
        // style={show_header === 'sticky' ? { with: '100%', paddingTop: 75 } : { width: '100%' }}
        >
          {children}
        </div>

        <div className='r-contact'>
          <div className='r-contact-mb'>
            <div className='container'>
              <Link href='/contact'>
                <a>
                  <div className='title'>
                    {/* <h3>連絡して下</h3> */}
                    <div className='content-contact'>
                      <h3>
                        <img
                          className='mail-icon'
                          src='/assets/images/mail.svg'
                          alt='mail-icon'
                        />
                        お問い合わせ
                      </h3>
                    </div>
                    {/* <p>
                                    ご相談、お見積、またはご希望の内容を送信いただけます。<br />
                                    お気軽にメールでお問い合わせください。いつでも承ります。
                                </p> */}
                    <p>
                      ご相談、お見積等について、こちらにお問い合わせください。
                    </p>
                  </div>
                </a>
              </Link>

              {/* <div className='btn-contact'>
                                <Link href='/contact'>
                                <a >
                                    <span>お問合せ</span>
                                    <svg width='79' height='40' viewBox='0 0 79 40' fill='none' xmlns='http://www.w3.org/2000/svg'>
                                        <path fill-rule='evenodd' clip-rule='evenodd' d='M77.4844 21.2272C77.8657 20.8459 78.0799 20.3287 78.0799 19.7894C78.0799 19.2501 77.8657 18.7329 77.4844 18.3516L60.2304 1.09759C59.8491 0.716251 59.3319 0.502019 58.7926 0.502019C58.2533 0.502019 57.7361 0.71625 57.3547 1.09759C56.9734 1.47892 56.7592 1.99613 56.7592 2.53542C56.7592 3.07471 56.9734 3.59191 57.3547 3.97325L71.1378 17.7563L2.60554 17.7534C2.33817 17.7534 2.07342 17.8061 1.8264 17.9084C1.57939 18.0107 1.35494 18.1607 1.16588 18.3497C0.976826 18.5388 0.826858 18.7632 0.724543 19.0103C0.622225 19.2573 0.569567 19.522 0.569565 19.7894C0.569565 20.0568 0.622226 20.3215 0.724542 20.5685C0.826861 20.8155 0.976832 21.04 1.16589 21.229C1.35495 21.4181 1.57939 21.5681 1.8264 21.6704C2.07342 21.7727 2.33817 21.8254 2.60553 21.8254L71.1378 21.8225L57.3547 35.6055C56.9734 35.9869 56.7592 36.5041 56.7592 37.0433C56.7592 37.5826 56.9734 38.0998 57.3547 38.4812C57.7361 38.8625 58.2533 39.0768 58.7926 39.0768C59.3319 39.0768 59.8491 38.8625 60.2304 38.4812L77.4844 21.2272Z' fill='#0063AA' />
                                    </svg>
                                </a>
                                </Link>
                            </div> */}
            </div>
          </div>
        </div>
        {/* end footer contact */}

        {/* footer */}
        <div id='footer'>
          {/* footer main */}
          <div className='container-fluid'>
            <div className='main'>
              <div className='row'>
                <div className='col-12 col-xl-12'>
                  <div className='header-footer'>
                    <Link href='/'>
                      <a className='logo'>
                        <img
                          src='/assets/images/logo-tcom-jpan-footer.svg'
                          alt='TCOM'
                        />
                      </a>
                    </Link>
                    <p>
                      ご予算に合わせて高品質・高コストパフォーマンスのオフショアサービスを提供します。
                    </p>
                  </div>
                </div>
                <div className='col-12 col-xl-12 custom-margin'>
                  <div className='social-mobile d-md-none'>
                    <h3 className='title'>{trans.layout.footer_title_keep}</h3>
                    <div className='social-link mb-21'>
                      <ul>
                        <li>
                          <a
                            href='https://www.facebook.com/TCOM.Corporation'
                            target={'_blank'}
                          >
                            <svg
                              width='33'
                              height='33'
                              viewBox='0 0 33 33'
                              fill='none'
                              xmlns='http://www.w3.org/2000/svg'
                            >
                              <circle
                                cx='16.5'
                                cy='16.5'
                                r='16.5'
                                fill='white'
                              />
                              <path
                                d='M20.1116 10.2951H21.9233V7.13974C21.6107 7.09674 20.5358 7 19.2839 7C16.6718 7 14.8825 8.643 14.8825 11.6627V14.4419H12V17.9693H14.8825L14.8825 26.845H18.4165V17.9701H21.1824L21.6215 14.4427H18.4157V12.0125C18.4165 10.993 18.6911 10.2951 20.1116 10.2951Z'
                                fill='#0063AA'
                              />
                            </svg>
                          </a>
                        </li>
                        <li>
                          <a
                            href='https://www.linkedin.com/company/tcom-corporation'
                            target={'_blank'}
                          >
                            <svg
                              width='33'
                              height='33'
                              viewBox='0 0 33 33'
                              fill='none'
                              xmlns='http://www.w3.org/2000/svg'
                            >
                              <path
                                d='M16.4999 0C25.6131 0 33 7.38787 33 16.5001C33 25.6122 25.6131 33 16.4999 33C7.38674 33 0 25.6121 0 16.5001C0 7.38798 7.38685 0 16.4999 0Z'
                                fill='white'
                              />
                              <path
                                d='M9.29779 22.671H12.3874V12.3729H9.29779V22.671ZM21.3346 12.0162C19.8351 12.0162 18.4935 12.5637 17.5417 13.7724V12.339H14.4407V22.6711H17.5417V17.0837C17.5417 15.9029 18.6235 14.751 19.9785 14.751C21.3336 14.751 21.6677 15.9029 21.6677 17.0549V22.67H24.7574V16.8249C24.7573 12.7648 22.8351 12.0162 21.3346 12.0162ZM10.8282 11.3437C11.6821 11.3437 12.3751 10.6507 12.3751 9.79681C12.3751 8.94292 11.6821 8.25 10.8282 8.25C9.97428 8.25 9.28125 8.94303 9.28125 9.79692C9.28125 10.6508 9.97428 11.3437 10.8282 11.3437Z'
                                fill='#0063AA'
                              />
                            </svg>
                          </a>
                        </li>
                        <li>
                          <a
                            href='https://www.youtube.com/channel/UCmeadb8H2pD_05cFz0qvDVw'
                            target={'_blank'}
                          >
                            <svg
                              width='33'
                              height='33'
                              viewBox='0 0 33 33'
                              fill='none'
                              xmlns='http://www.w3.org/2000/svg'
                            >
                              <circle
                                cx='16.5'
                                cy='16.5'
                                r='16.5'
                                fill='white'
                              />
                              <path
                                d='M24.6452 12.8745C24.5484 12.5115 24.3587 12.1805 24.095 11.9146C23.8313 11.6487 23.5028 11.4572 23.1425 11.3592C21.8163 11 16.5 11 16.5 11C16.5 11 11.1837 11 9.85748 11.3573C9.49703 11.4549 9.16842 11.6463 8.90465 11.9123C8.64089 12.1783 8.45126 12.5095 8.3548 12.8726C8 14.2102 8 17 8 17C8 17 8 19.7898 8.3548 21.1255C8.55022 21.8631 9.12701 22.4439 9.85748 22.6408C11.1837 23 16.5 23 16.5 23C16.5 23 21.8163 23 23.1425 22.6408C23.8749 22.4439 24.4498 21.8631 24.6452 21.1255C25 19.7898 25 17 25 17C25 17 25 14.2102 24.6452 12.8745ZM14.8114 19.5605V14.4395L19.2132 16.9809L14.8114 19.5605Z'
                                fill='#0063AA'
                              />
                            </svg>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className='row'>
                    <div className='col-xs-12 col-md-12 col-lg-6 col-xl-3 col-social'>
                      <h3 className='title'>
                        {trans.layout.footer_title_tcomjp}
                        <svg
                          width={31}
                          height={22}
                          viewBox='0 0 31 22'
                          fill='none'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <path
                            d='M30.4414 0.140625H0.441406V21.1332H30.4414V0.140625Z'
                            fill='#F1F0F0'
                          />
                          <path
                            d='M15.4377 4.46094C18.8383 4.46094 21.5903 7.22357 21.5903 10.6373C21.5903 14.051 18.8383 16.8136 15.4377 16.8136C12.0372 16.8136 9.28516 14.051 9.28516 10.6373C9.28516 7.22357 12.0372 4.46094 15.4377 4.46094Z'
                            fill='#D61F30'
                          />
                        </svg>
                      </h3>
                      <ul>
                        <li>
                          <span className='icon'>
                            <svg
                              width={25}
                              height={25}
                              viewBox='0 0 25 25'
                              fill='none'
                              xmlns='http://www.w3.org/2000/svg'
                            >
                              <g opacity='0.5'>
                                <path
                                  d='M12.4414 13.1406C14.0983 13.1406 15.4414 11.7975 15.4414 10.1406C15.4414 8.48377 14.0983 7.14062 12.4414 7.14062C10.7846 7.14062 9.44141 8.48377 9.44141 10.1406C9.44141 11.7975 10.7846 13.1406 12.4414 13.1406Z'
                                  stroke='white'
                                  strokeWidth={2}
                                  strokeLinecap='round'
                                  strokeLinejoin='round'
                                />
                                <path
                                  d='M12.4414 2.14062C10.3197 2.14063 8.28484 2.98348 6.78455 4.48377C5.28426 5.98406 4.44141 8.01889 4.44141 10.1406C4.44141 12.0326 4.84341 13.2706 5.94141 14.6406L12.4414 22.1406L18.9414 14.6406C20.0394 13.2706 20.4414 12.0326 20.4414 10.1406C20.4414 8.01889 19.5986 5.98406 18.0983 4.48377C16.598 2.98348 14.5631 2.14063 12.4414 2.14062V2.14062Z'
                                  stroke='white'
                                  strokeWidth={2}
                                  strokeLinecap='round'
                                  strokeLinejoin='round'
                                />
                              </g>
                            </svg>
                          </span>
                          <span>〒190-0023 東京都立川市柴崎町3-8-5 </span>
                        </li>
                        <li style={{ marginTop: '-4px' }}>
                          <div className='icon'>
                            <svg
                              width={25}
                              height={25}
                              viewBox='0 0 25 25'
                              fill='none'
                              xmlns='http://www.w3.org/2000/svg'
                            >
                              <g opacity='0.5'>
                                <path
                                  fillRule='evenodd'
                                  clipRule='evenodd'
                                  d='M17.4414 23.1406C17.9718 23.1406 18.4805 22.9299 18.8556 22.5548C19.2307 22.1798 19.4414 21.6711 19.4414 21.1406V3.14062C19.4414 2.61019 19.2307 2.10148 18.8556 1.72641C18.4805 1.35134 17.9718 1.14063 17.4414 1.14062H7.44141C6.91097 1.14063 6.40227 1.35134 6.02719 1.72641C5.65212 2.10148 5.44141 2.61019 5.44141 3.14062V21.1406C5.44141 21.6711 5.65212 22.1798 6.02719 22.5548C6.40227 22.9299 6.91097 23.1406 7.44141 23.1406H17.4414ZM11.4414 21.1406H7.44141V3.14062H17.4414V21.1406H13.4414V20.1096C13.4414 19.8444 13.336 19.5901 13.1485 19.4025C12.961 19.215 12.7066 19.1096 12.4414 19.1096C12.1762 19.1096 11.9218 19.215 11.7343 19.4025C11.5468 19.5901 11.4414 19.8444 11.4414 20.1096V21.1406Z'
                                  fill='white'
                                />
                              </g>
                            </svg>
                          </div>
                          <span>042 512 9160</span>
                        </li>
                        <li style={{ marginTop: '-7px' }}>
                          <div className='icon'>
                            <svg
                              width={25}
                              height={25}
                              viewBox='0 0 25 25'
                              fill='none'
                              xmlns='http://www.w3.org/2000/svg'
                            >
                              <g opacity='0.5'>
                                <path
                                  d='M19.4414 4.14062H5.44141C4.64576 4.14062 3.8827 4.4567 3.32009 5.0193C2.75748 5.58191 2.44141 6.34498 2.44141 7.14062V17.1406C2.44141 17.9363 2.75748 18.6993 3.32009 19.2619C3.8827 19.8246 4.64576 20.1406 5.44141 20.1406H19.4414C20.2371 20.1406 21.0001 19.8246 21.5627 19.2619C22.1253 18.6993 22.4414 17.9363 22.4414 17.1406V7.14062C22.4414 6.34498 22.1253 5.58191 21.5627 5.0193C21.0001 4.4567 20.2371 4.14063 19.4414 4.14062ZM18.7714 6.14062L12.4414 10.8906L6.11141 6.14062H18.7714ZM19.4414 18.1406H5.44141C5.17619 18.1406 4.92184 18.0353 4.7343 17.8477C4.54676 17.6602 4.44141 17.4058 4.44141 17.1406V7.39062L11.8414 12.9406C12.0145 13.0704 12.225 13.1406 12.4414 13.1406C12.6578 13.1406 12.8683 13.0704 13.0414 12.9406L20.4414 7.39062V17.1406C20.4414 17.4058 20.3361 17.6602 20.1485 17.8477C19.961 18.0353 19.7066 18.1406 19.4414 18.1406Z'
                                  fill='white'
                                />
                              </g>
                            </svg>
                          </div>
                          <span>{trans.layout.email_tcomjp}</span>
                        </li>
                      </ul>
                      <div className='social d-none d-md-block'>
                        <h3 className='title ndasndanq2'>
                          {trans.layout.footer_title_keep}
                        </h3>
                        <div className='social-link mb-83'>
                          <ul>
                            <li>
                              <a
                                href='https://www.facebook.com/TCOM.Corporation'
                                target={'_blank'}
                              >
                                <svg
                                  width='33'
                                  height='33'
                                  viewBox='0 0 33 33'
                                  fill='none'
                                  xmlns='http://www.w3.org/2000/svg'
                                >
                                  <circle
                                    cx='16.5'
                                    cy='16.5'
                                    r='16.5'
                                    fill='white'
                                  />
                                  <path
                                    d='M20.1116 10.2951H21.9233V7.13974C21.6107 7.09674 20.5358 7 19.2839 7C16.6718 7 14.8825 8.643 14.8825 11.6627V14.4419H12V17.9693H14.8825L14.8825 26.845H18.4165V17.9701H21.1824L21.6215 14.4427H18.4157V12.0125C18.4165 10.993 18.6911 10.2951 20.1116 10.2951Z'
                                    fill='#0063AA'
                                  />
                                </svg>
                              </a>
                            </li>
                            <li className='line'>
                              <a
                                href='https://www.linkedin.com/company/tcom-corporation'
                                target={'_blank'}
                              >
                                <svg
                                  width='33'
                                  height='33'
                                  viewBox='0 0 33 33'
                                  fill='none'
                                  xmlns='http://www.w3.org/2000/svg'
                                >
                                  <path
                                    d='M16.4999 0C25.6131 0 33 7.38787 33 16.5001C33 25.6122 25.6131 33 16.4999 33C7.38674 33 0 25.6121 0 16.5001C0 7.38798 7.38685 0 16.4999 0Z'
                                    fill='white'
                                  />
                                  <path
                                    d='M9.29779 22.671H12.3874V12.3729H9.29779V22.671ZM21.3346 12.0162C19.8351 12.0162 18.4935 12.5637 17.5417 13.7724V12.339H14.4407V22.6711H17.5417V17.0837C17.5417 15.9029 18.6235 14.751 19.9785 14.751C21.3336 14.751 21.6677 15.9029 21.6677 17.0549V22.67H24.7574V16.8249C24.7573 12.7648 22.8351 12.0162 21.3346 12.0162ZM10.8282 11.3437C11.6821 11.3437 12.3751 10.6507 12.3751 9.79681C12.3751 8.94292 11.6821 8.25 10.8282 8.25C9.97428 8.25 9.28125 8.94303 9.28125 9.79692C9.28125 10.6508 9.97428 11.3437 10.8282 11.3437Z'
                                    fill='#0063AA'
                                  />
                                </svg>
                              </a>
                            </li>
                            <li className='whatsapp'>
                              <a
                                href='https://www.youtube.com/channel/UCmeadb8H2pD_05cFz0qvDVw'
                                target={'_blank'}
                              >
                                <svg
                                  width='33'
                                  height='33'
                                  viewBox='0 0 33 33'
                                  fill='none'
                                  xmlns='http://www.w3.org/2000/svg'
                                >
                                  <circle
                                    cx='16.5'
                                    cy='16.5'
                                    r='16.5'
                                    fill='white'
                                  />
                                  <path
                                    d='M24.6452 12.8745C24.5484 12.5115 24.3587 12.1805 24.095 11.9146C23.8313 11.6487 23.5028 11.4572 23.1425 11.3592C21.8163 11 16.5 11 16.5 11C16.5 11 11.1837 11 9.85748 11.3573C9.49703 11.4549 9.16842 11.6463 8.90465 11.9123C8.64089 12.1783 8.45126 12.5095 8.3548 12.8726C8 14.2102 8 17 8 17C8 17 8 19.7898 8.3548 21.1255C8.55022 21.8631 9.12701 22.4439 9.85748 22.6408C11.1837 23 16.5 23 16.5 23C16.5 23 21.8163 23 23.1425 22.6408C23.8749 22.4439 24.4498 21.8631 24.6452 21.1255C25 19.7898 25 17 25 17C25 17 25 14.2102 24.6452 12.8745ZM14.8114 19.5605V14.4395L19.2132 16.9809L14.8114 19.5605Z'
                                    fill='#0063AA'
                                  />
                                </svg>
                              </a>
                            </li>
                            {/* <li className='linkedin'>
                                                            <a href='#'>
                                                                <svg width={34} height={34} viewBox='0 0 34 34' fill='none' xmlns='http://www.w3.org/2000/svg'>
                                                                    <g clipPath='url(#clip0_400_1430)'>
                                                                        <path d='M16.9413 0.140625C26.0546 0.140625 33.4414 7.5285 33.4414 16.6407C33.4414 25.7529 26.0546 33.1406 16.9413 33.1406C7.82814 33.1406 0.441406 25.7528 0.441406 16.6407C0.441406 7.52861 7.82826 0.140625 16.9413 0.140625Z' fill='white' />
                                                                        <path d='M9.7392 22.8116H12.8288V12.5135H9.7392V22.8116ZM21.776 12.1568C20.2765 12.1568 18.9349 12.7044 17.9831 13.913V12.4796H14.8821V22.8117H17.9831V17.2244C17.9831 16.0436 19.0649 14.8916 20.4199 14.8916C21.775 14.8916 22.1091 16.0436 22.1091 17.1955V22.8107H25.1988V16.9655C25.1987 12.9054 23.2765 12.1568 21.776 12.1568ZM11.2696 11.4844C12.1235 11.4844 12.8165 10.7913 12.8165 9.93743C12.8165 9.08355 12.1235 8.39062 11.2696 8.39062C10.4157 8.39062 9.72266 9.08366 9.72266 9.93755C9.72266 10.7914 10.4157 11.4844 11.2696 11.4844Z' fill='#0063AA' />
                                                                    </g>
                                                                    <defs>
                                                                        <clipPath id='clip0_400_1430'>
                                                                            <rect width={33} height={33} fill='white' transform='translate(0.441406 0.140625)' />
                                                                        </clipPath>
                                                                    </defs>
                                                                </svg>
                                                            </a>
                                                        </li> */}
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className='col-xs-12 col-md-12 col-lg-4 col-xl-4 customMarginLeftFooter'>
                      <h3 className='title'>
                        {trans.layout.footer_title_tcomvn}
                        <svg
                          width={31}
                          height={21}
                          viewBox='0 0 31 21'
                          fill='none'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <path
                            d='M11.9625 0.140625H0.441406V20.2121H11.9625H30.4414V0.140625H11.9625Z'
                            fill='#D81F2A'
                          />
                          <path
                            d='M15.4373 4.36914L16.7683 8.4729H21.0642L17.5883 11.0079L18.9133 15.1117L15.4373 12.5767L11.9614 15.1117L13.2924 11.0079L9.81641 8.4729H14.1123L15.4373 4.36914Z'
                            fill='#FEDA46'
                          />
                        </svg>
                      </h3>
                      <ul>
                        {/* <li>
                          <span className='icon'>
                            <svg
                              width={25}
                              height={25}
                              viewBox='0 0 25 25'
                              fill='none'
                              xmlns='http://www.w3.org/2000/svg'
                            >
                              <g opacity='0.5'>
                                <path
                                  d='M12.4414 13.1406C14.0983 13.1406 15.4414 11.7975 15.4414 10.1406C15.4414 8.48377 14.0983 7.14062 12.4414 7.14062C10.7846 7.14062 9.44141 8.48377 9.44141 10.1406C9.44141 11.7975 10.7846 13.1406 12.4414 13.1406Z'
                                  stroke='white'
                                  strokeWidth={2}
                                  strokeLinecap='round'
                                  strokeLinejoin='round'
                                />
                                <path
                                  d='M12.4414 2.14062C10.3197 2.14063 8.28484 2.98348 6.78455 4.48377C5.28426 5.98406 4.44141 8.01889 4.44141 10.1406C4.44141 12.0326 4.84341 13.2706 5.94141 14.6406L12.4414 22.1406L18.9414 14.6406C20.0394 13.2706 20.4414 12.0326 20.4414 10.1406C20.4414 8.01889 19.5986 5.98406 18.0983 4.48377C16.598 2.98348 14.5631 2.14063 12.4414 2.14062V2.14062Z'
                                  stroke='white'
                                  strokeWidth={2}
                                  strokeLinecap='round'
                                  strokeLinejoin='round'
                                />
                              </g>
                            </svg>
                            <strong>本部</strong>
                          </span>
                          <p>
                            No. 26B/92, Group 11, Hoang Van Thu Ward, <br />{' '}
                            Hoang Mai District, Ha Noi City, Viet Nam
                          </p>
                        </li> */}
                        <li>
                          <div className='icon'>
                            <svg
                              width={25}
                              height={25}
                              viewBox='0 0 25 25'
                              fill='none'
                              xmlns='http://www.w3.org/2000/svg'
                            >
                              <g opacity='0.68'>
                                <path
                                  d='M5.03906 3.17969V19.6797H14.0391V3.17969H5.03906ZM4.28906 1.67969H14.7891C14.988 1.67969 15.1787 1.75871 15.3194 1.89936C15.46 2.04001 15.5391 2.23078 15.5391 2.42969V20.4297C15.5391 20.6286 15.46 20.8194 15.3194 20.96C15.1787 21.1007 14.988 21.1797 14.7891 21.1797H4.28906C4.09015 21.1797 3.89938 21.1007 3.75873 20.96C3.61808 20.8194 3.53906 20.6286 3.53906 20.4297V2.42969C3.53906 2.23078 3.61808 2.04001 3.75873 1.89936C3.89938 1.75871 4.09015 1.67969 4.28906 1.67969Z'
                                  fill='white'
                                />
                                <path
                                  d='M6.53906 6.17969H12.5391V7.67969H6.53906V6.17969ZM6.53906 10.6797H12.5391V12.1797H6.53906V10.6797ZM6.53906 15.1797H12.5391V16.6797H6.53906V15.1797ZM15.5391 12.1797H18.5391V13.6797H15.5391V12.1797ZM15.5391 15.1797H18.5391V16.6797H15.5391V15.1797ZM2.03906 19.6797H23.0391V21.1797H2.03906V19.6797Z'
                                  fill='white'
                                />
                                <path
                                  d='M15.5391 9.17969V19.6797H20.0391V9.17969H15.5391ZM14.7891 7.67969H20.7891C20.988 7.67969 21.1787 7.75871 21.3194 7.89936C21.46 8.04001 21.5391 8.23078 21.5391 8.42969V20.4297C21.5391 20.6286 21.46 20.8194 21.3194 20.96C21.1787 21.1007 20.988 21.1797 20.7891 21.1797H14.7891C14.5902 21.1797 14.3994 21.1007 14.2587 20.96C14.1181 20.8194 14.0391 20.6286 14.0391 20.4297V8.42969C14.0391 8.23078 14.1181 8.04001 14.2587 7.89936C14.3994 7.75871 14.5902 7.67969 14.7891 7.67969Z'
                                  fill='white'
                                />
                              </g>
                            </svg>
                            <strong>{trans.layout.office_hanoi}</strong>
                            {/* <strong>Văn phòng Hà Nội</strong> */}
                          </div>
                          <p>
                            3F, Fafim A Building, 19 Nguyen Trai Street, <br />{' '}
                            Thanh Xuan District, Ha Noi City, Viet Nam
                          </p>
                        </li>
                        <li>
                          <div className='icon'>
                            <svg
                              width={25}
                              height={25}
                              viewBox='0 0 25 25'
                              fill='none'
                              xmlns='http://www.w3.org/2000/svg'
                            >
                              <g opacity='0.68'>
                                <path
                                  d='M5.03906 3.17969V19.6797H14.0391V3.17969H5.03906ZM4.28906 1.67969H14.7891C14.988 1.67969 15.1787 1.75871 15.3194 1.89936C15.46 2.04001 15.5391 2.23078 15.5391 2.42969V20.4297C15.5391 20.6286 15.46 20.8194 15.3194 20.96C15.1787 21.1007 14.988 21.1797 14.7891 21.1797H4.28906C4.09015 21.1797 3.89938 21.1007 3.75873 20.96C3.61808 20.8194 3.53906 20.6286 3.53906 20.4297V2.42969C3.53906 2.23078 3.61808 2.04001 3.75873 1.89936C3.89938 1.75871 4.09015 1.67969 4.28906 1.67969Z'
                                  fill='white'
                                />
                                <path
                                  d='M6.53906 6.17969H12.5391V7.67969H6.53906V6.17969ZM6.53906 10.6797H12.5391V12.1797H6.53906V10.6797ZM6.53906 15.1797H12.5391V16.6797H6.53906V15.1797ZM15.5391 12.1797H18.5391V13.6797H15.5391V12.1797ZM15.5391 15.1797H18.5391V16.6797H15.5391V15.1797ZM2.03906 19.6797H23.0391V21.1797H2.03906V19.6797Z'
                                  fill='white'
                                />
                                <path
                                  d='M15.5391 9.17969V19.6797H20.0391V9.17969H15.5391ZM14.7891 7.67969H20.7891C20.988 7.67969 21.1787 7.75871 21.3194 7.89936C21.46 8.04001 21.5391 8.23078 21.5391 8.42969V20.4297C21.5391 20.6286 21.46 20.8194 21.3194 20.96C21.1787 21.1007 20.988 21.1797 20.7891 21.1797H14.7891C14.5902 21.1797 14.3994 21.1007 14.2587 20.96C14.1181 20.8194 14.0391 20.6286 14.0391 20.4297V8.42969C14.0391 8.23078 14.1181 8.04001 14.2587 7.89936C14.3994 7.75871 14.5902 7.67969 14.7891 7.67969Z'
                                  fill='white'
                                />
                              </g>
                            </svg>
                            {/* <strong className='text-white'>オフィス</strong> */}
                            <strong>{trans.layout.office_hcm}</strong>
                          </div>
                          <p>5th Floor, VTD Building, No. 17 Ho Van Hue, Ward 9, <br />Phu Nhuan District, Ho Chi Minh City
                          </p>
                        </li>
                      </ul>
                    </div>

                    <div className='col-8 col-xs-8 col-sm-7 col-md-7 col-lg-7 col-xl-3 col-service'>
                      <h3 className='title'>{trans.layout.service}</h3>
                      <ul className='link-list'>
                        {service.map(item => {
                          return (
                            <li>
                              <a href={`/service/${item.seo_url}`}>
                                {item.title}
                              </a>
                            </li>
                          );
                        })}
                        <li>
                          <a href={`/offshore`}>{trans.layout.offshore}</a>
                        </li>
                      </ul>
                      <div className='policy'>
                        <a href='#'>{trans.layout.terms_of_service}</a>
                        <a href='#'>{trans.layout.Privacy_Policy}</a>
                      </div>
                    </div>
                    <div className='col-4 col-xs-4 col-sm-5 col-md-5 col-lg-5 col-xl-2 col-menu'>
                      <h3 className='title'>
                        {trans.layout.footer_title_menu}
                      </h3>
                      <ul className='link-list'>
                        <li>
                          <Link href='/'>{trans.layout.home}</Link>
                        </li>
                        <li>
                          <Link href={'/about'}>
                            <a>{trans.layout.about}</a>
                          </Link>
                        </li>
                        <li>
                          <Link href={'/service'}>
                            <a>{trans.layout.service}</a>
                          </Link>
                        </li>
                        <li>
                          <Link href='/project'>
                            <a>{trans.layout.project}</a>
                          </Link>
                        </li>
                        <li>
                          <Link href={'/contact'}>
                            <a>{trans.layout.contact}</a>
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* end footer main */}
          {/* copyright */}
          <div className='copyright'>
            <p>
              Copyright © 2022 TCOM JAPAN
              <span>
                <img src='/assets/images/logo-copyright.svg' />
              </span>
            </p>
          </div>
          {/* end copyright */}
          <div className='homeIcon'>
            <Link href='/'>
              <a className='logo'>
                <img
                  className='width-respon'
                  src='/assets/images/other/ico-home.png'
                  alt='TCOM JAPAN'
                  style={{ width: '72px' }}
                />
              </a>
            </Link>
          </div>
        </div>
        {/* end footer */}
      </div>
    </>
  );
}
export default Layout;
